import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "user";
const modelPath = "/anggota/request-reset-password/";

class User extends ModelBase {
  constructor() {
    let model = {
      npm: "",
      email: "",
    };
    super(modelName, model, ["email"], ["npm"], modelPath);
  }

  getPayload() {
    let data = super.getPayload();
    data.email = data.email.toLowerCase();
    return data;
  }
}

export default User;