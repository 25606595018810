<template>
  <section class="hero is-success is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="column is-5 is-offset-4">
          <form v-if="!showMessage">
            <div class="box">
              <b-field :type="{'is-danger': errorMap.npm}" :message="errorMap.npm">
                <b-input
                  v-model="user.npm"
                  size="is-large"
                  placeholder="NPM (Mahasiswa)"
                  iconPack="fas"
                  @input="validateInput('npm')"
                ></b-input>
              </b-field>
              <b-field :type="{'is-danger': errorMap.email}" :message="errorMap.email">
                <b-input
                  v-model="user.email"
                  size="is-large"
                  placeholder="Email"
                  iconPack="fas"
                  @input="validateInput('email')"
                ></b-input>
              </b-field>
              <b-button
                type="is-primary"
                size="is-large"
                expanded
                :disabled="!isValid"
                @click.stop.prevent="saveObject"
              >Kirim</b-button>
              <b-button class="button is-fullwidth is-primary is-large" @click.stop="cancel">Batal</b-button>
              <small>*npm hanya wajib diisi oleh mahasiswa</small>
            </div>
          </form>
          <div class="box" v-else>
            <h5>Silahkan cek email untuk mengganti sandi.</h5>
            <a
              @click.stop="$router.go(-1)"
            >Kembali ke halaman login</a>
          </div>
        </div>
        <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
      </div>
    </div>
  </section>
</template>

<script>
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import User from "../models/reqResetPass.js";

export default {
  name: "RequestResetPassword",
  mixins: [createUpdateMixin],
  data() {
    this.userMdl = new User();
    this.objectMdl = this.userMdl;
    let obv = this.userMdl.getObservables();
    obv.sent = false;
    return obv;
  },
  computed: {
    showMessage() {
      return this.sent;
    }
  },
  methods: {
    onSaved(respObj) { // eslint-disable-line no-unused-vars
      this.sent = true;
    }
  },
  watch: {
    user: {
      handler(newValue, oldValue) {
        if (!this.userMdl.getEdited()) {
          this.setEdited(this.isCreate || !!oldValue.id);
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
.box {
  font-size: 14px;
  font-weight: 300;
}

button {
  margin: 0.25rem;
}

::v-deep .is-right {
  color: $ppds-blue !important;
}
</style>
